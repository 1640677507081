<template>
  <v-app id="app">
    <router-view></router-view>
  </v-app>
</template>

<script>
import store from "@/store";
import router from "@/router";
export default {
  name: "App",
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.goBackCheck);
    }
  },
  destroyed() {
    window.removeEventListener("popstate", this.goBackCheck);
  },
  methods: {
    goBackCheck() {
      if (store.state.showBuyDialog) {
        store.commit("setShowBuyDialog", false);
        router.go(1);
      }
    }
  }
};
</script>

<style scoped></style>
